import React, { useState, useMemo, useEffect } from "react";
import { Alert, Button, Dropdown, Spinner, Table } from "react-bootstrap";
import ProjectHistory from "../projects/history";

export default function BulkTable() {
  const [data, setData] = useState([]);
  // FIlter
  const [bigTable, setBigTable] = useState(false);
  const [pinList, setPinList] = useState([]);
  const pinHeight = 135;
  const [activeSub, setActiveSub] = useState(["VA Bulketabell"]);
  const [activeSubSub, setActiveSubSub] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const res = await fetch(
        "https://prosjekt.tkva.no/api/projects.php?bulkVA",
        {
          credentials: "include",
        }
      );
      const d = await res.json();
      setData(d);
    } catch (err) {
      return;
    }
  };

  const sortedPinList = useMemo(() => {
    const comparePinItems = (a, b) => {
      // Sammenlign parentIds først
      if (a.parentId !== b.parentId) {
        return a.parentId - b.parentId;
      }

      // Hvis parentIds er like, sammenlign subIds
      if (a.subId !== b.subId) {
        return a.subId - b.subId;
      }

      // Hvis subIds også er like, sammenlign subsubIds
      if (a.subsubId !== b.subsubId) {
        return a.subsubId - b.subsubId;
      }

      return 0;
    };

    return [...pinList].sort(comparePinItems);
  }, [pinList]);

  return (
    <div
      id="projectTable"
      style={{
        cursor: "default",
        position: "relative",
      }}
      //onLoad={() => scrollTable()}
      //onScroll={(e) => setScrollTable(e.nativeEvent.target.scrollTop)}
      //onScroll={setScrollTable}
    >
      <Button size="sm" onClick={() => setBigTable((prev) => !prev)}>
        {bigTable ? "Liten tabell" : "Stor tabell"}
      </Button>
      <Table hover className="sticky-thc">
        <thead>
          <tr>
            <th colSpan={2} style={{ textAlign: "left", width: "25%" }}>
              Prosjekt
            </th>
            <th>PL</th>
            {bigTable
              ? Array.from({ length: 10 }, (_, index) => (
                  <th key={index}>{parseInt(data[0]?.year) + index}</th>
                ))
              : Array.from({ length: 4 }, (_, index) => (
                  <th key={index}>{parseInt(data[0]?.year) + index}</th>
                ))}
            <th>#</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((proj, index) => {
            return (
              <React.Fragment key={index}>
                <tr
                  className={
                    pinList.find((item) => item.pnr === proj.name)
                      ? `mainProject pinned`
                      : `mainProject`
                  }
                  style={
                    pinList.find((item) => item.pnr === proj.name)
                      ? {
                          cursor: "pointer",
                          height: "110px",
                          top:
                            pinHeight +
                            parseInt(
                              parseInt(
                                sortedPinList.findIndex(
                                  (item) => item.pnr === proj.name
                                )
                              ) * 105
                            ) +
                            "px",
                        }
                      : { cursor: "pointer", height: "110px" }
                  }
                  onClick={() => {
                    setActiveSub((prev) => {
                      if (prev.includes(proj.name)) {
                        // Fjern prosjektet hvis det allerede er i listen
                        return prev.filter((name) => name !== proj.name);
                      } else {
                        // Legg til prosjektet hvis det ikke er i listen
                        return [...prev, proj.name];
                      }
                    });
                  }}
                >
                  <td colSpan={2}>{proj.name}</td>

                  <td>{formatName(proj.pl)}</td>

                  <td>{convertNumber(proj.e1)}</td>

                  <td>{convertNumber(proj.e2)}</td>

                  <td>{convertNumber(proj.e3)}</td>

                  <td>{convertNumber(proj.e4)}</td>
                  {bigTable && (
                    <>
                      <td>{convertNumber(proj.e5)}</td>

                      <td>{convertNumber(proj.e6)}</td>

                      <td>{convertNumber(proj.e7)}</td>

                      <td>{convertNumber(proj.e8)}</td>

                      <td>{convertNumber(proj.e9)}</td>

                      <td>{convertNumber(proj.e10)}</td>
                    </>
                  )}
                  <td
                    onClick={() =>
                      setActiveSub((prev) => {
                        if (prev.includes(proj.name)) {
                          // Fjern prosjektet hvis det allerede er i listen
                          return prev.filter((name) => name !== proj.name);
                        } else {
                          // Legg til prosjektet hvis det ikke er i listen
                          return [...prev, proj.name];
                        }
                      })
                    }
                  >
                    <>
                      {pinList.find((item) => item.pnr === proj.name) ? (
                        <i
                          className="bi bi-pin-angle-fill"
                          onClick={() =>
                            setPinList((prev) =>
                              prev.filter((item) => item.pnr !== proj.name)
                            )
                          }
                        />
                      ) : (
                        <i
                          className="bi bi-pin-angle"
                          onClick={() =>
                            setPinList((prev) => [
                              ...prev,
                              {
                                pnr: proj.name,
                                parentId: index,
                                subId: null,
                                subsubId: null,
                              },
                            ])
                          }
                        />
                      )}
                    </>
                  </td>
                </tr>
                {proj?.subData?.map((subProj, subIndex) => {
                  return (
                    <React.Fragment key={subIndex}>
                      <tr
                        key={subIndex}
                        className={`
                            ${
                              pinList.find((item) => item.pnr === subProj.pnr)
                                ? `subProject
                                
                                pinned pinCount${
                                  pinList.find(
                                    (item) => item.pnr === subProj.pnr
                                  )?.count
                                } `
                                : `subProject`
                            } 
                            `}
                        style={
                          activeSub.includes(proj.name)
                            ? {
                                display: "table-row",
                                cursor: "pointer",
                                height: "110px",
                                top: pinList.find(
                                  (prev) => prev.pnr === subProj.pnr
                                )
                                  ? pinHeight +
                                    parseInt(
                                      parseInt(
                                        sortedPinList.findIndex(
                                          (item) => item.pnr === subProj.pnr
                                        )
                                      ) * 105
                                    ) +
                                    "px"
                                  : 0, // Sett toppen til 0 hvis elementet ikke er festet
                              }
                            : { display: "none", cursor: "pointer" }
                        }
                        onClick={() => {
                          setActiveSubSub((prev) => {
                            if (prev.includes(subProj.pnr)) {
                              // Fjern prosjektet hvis det allerede er i listen
                              return prev.filter(
                                (name) => name !== subProj.pnr
                              );
                            } else {
                              // Legg til prosjektet hvis det ikke er i listen
                              return [...prev, subProj.pnr];
                            }
                          });
                        }}
                      >
                        <td colSpan={2}>
                          <div
                            className="d-flex flex-column"
                            style={{ marginLeft: 25 }}
                          >
                            {subProj.pnr} - {subProj.pname}
                            <span className="projType">{proj.name}</span>
                          </div>
                        </td>
                        <td>{formatName(subProj.pl)}</td>
                        <td>{convertNumber(subProj.e1)}</td>
                        <td>{convertNumber(subProj.e2)}</td>
                        <td>{convertNumber(subProj.e3)}</td>
                        <td>{convertNumber(subProj.e4)}</td>
                        {bigTable && (
                          <>
                            <td>{convertNumber(subProj.e5)}</td>
                            <td>{convertNumber(subProj.e6)}</td>
                            <td>{convertNumber(subProj.e7)}</td>
                            <td>{convertNumber(subProj.e8)}</td>
                            <td>{convertNumber(subProj.e9)}</td>
                            <td>{convertNumber(subProj.e10)}</td>
                          </>
                        )}
                        <td
                          onClick={() =>
                            setActiveSubSub((prev) => {
                              if (prev.includes(subProj.pnr)) {
                                // Fjern prosjektet hvis det allerede er i listen
                                return prev.filter(
                                  (name) => name !== subProj.pnr
                                );
                              } else {
                                // Legg til prosjektet hvis det ikke er i listen
                                return [...prev, subProj.pnr];
                              }
                            })
                          }
                        >
                          <>
                            {pinList.find(
                              (item) => item.pnr === subProj.pnr
                            ) ? (
                              <i
                                className="bi bi-pin-angle-fill"
                                onClick={() =>
                                  setPinList((prev) =>
                                    prev.filter(
                                      (item) => item.pnr !== subProj.pnr
                                    )
                                  )
                                }
                              />
                            ) : (
                              <i
                                className="bi bi-pin-angle"
                                onClick={() =>
                                  setPinList((prev) => [
                                    ...prev,
                                    {
                                      pnr: subProj.pnr,
                                      parentId: index,
                                      subId: subIndex,
                                      subsubId: null,
                                    },
                                  ])
                                }
                              />
                            )}
                            <br />

                            <ProjectHistory project={subProj.pnr}>
                              <i
                                className="bi bi-chat-quote"
                                aria-expanded="false"
                              />
                            </ProjectHistory>
                            {parseInt(subProj.reportOpen) ? (
                              <>
                                {parseInt(subProj.reportAccepted) ? (
                                  <i
                                    style={{ color: "green" }}
                                    className="bi bi-circle-fill"
                                  />
                                ) : (
                                  <>
                                    {parseInt(subProj.reportSent) ? (
                                      <i
                                        style={{ color: "yellow" }}
                                        className="bi bi-circle-fill"
                                      />
                                    ) : (
                                      <i
                                        style={{ color: "red" }}
                                        className="bi bi-circle-fill"
                                      />
                                    )}
                                  </>
                                )}
                              </>
                            ) : (
                              /* Rapporten er ikke åpnet */
                              <>
                                {parseInt(subProj.reportAccepted) ? (
                                  <i
                                    style={{ color: "green" }}
                                    className="bi bi-circle-fill"
                                  />
                                ) : (
                                  " "
                                )}
                              </>
                            )}
                          </>
                        </td>
                      </tr>
                      {subProj?.subData?.map((subsubProj, subsubIndex) => {
                        return (
                          <tr
                            key={subsubIndex}
                            className={`
                                  ${
                                    pinList.find(
                                      (item) => item.pnr === subsubProj.pnr
                                    )
                                      ? `subsubProject 
                                    
                                    pinned pinCount${
                                      pinList.find(
                                        (item) => item.pnr === subsubProj.pnr
                                      )?.count
                                    }`
                                      : `subsubProject
                                   
                                    enhet-${subsubProj.enhet}`
                                  }
                                `}
                            style={
                              activeSub.includes(proj.name) &&
                              activeSubSub.includes(subProj.pnr)
                                ? {
                                    display: "table-row",
                                    height: "110px",
                                    top: pinList.find(
                                      (prev) => prev.pnr === subsubProj.pnr
                                    )
                                      ? pinHeight +
                                        parseInt(
                                          parseInt(
                                            sortedPinList.findIndex(
                                              (item) =>
                                                item.pnr === subsubProj.pnr
                                            )
                                          ) * 105
                                        ) +
                                        "px"
                                      : 0, // Sett toppen til 0 hvis elementet ikke er festet
                                  }
                                : { display: "none" }
                            }
                          >
                            <td colSpan={2}>
                              <div
                                className="d-flex flex-column"
                                style={{ marginLeft: 45 }}
                              >
                                {subsubProj.pnr} - {subsubProj.pname}
                                <span className="projType">
                                  {subProj.pnr} - {subProj.pname}
                                </span>
                              </div>
                            </td>
                            <td>{formatName(subsubProj.pl)}</td>
                            <td>{convertNumber(subsubProj.e1)}</td>
                            <td>{convertNumber(subsubProj.e2)}</td>
                            <td>{convertNumber(subsubProj.e3)}</td>
                            <td>{convertNumber(subsubProj.e4)}</td>
                            {bigTable && (
                              <>
                                <td>{convertNumber(subsubProj.e5)}</td>
                                <td>{convertNumber(subsubProj.e6)}</td>
                                <td>{convertNumber(subsubProj.e7)}</td>
                                <td>{convertNumber(subsubProj.e8)}</td>
                                <td>{convertNumber(subsubProj.e9)}</td>
                                <td>{convertNumber(subsubProj.e10)}</td>
                              </>
                            )}
                            <td style={{ cursor: "pointer" }}>
                              <>
                                {pinList.find(
                                  (item) => item.pnr === subsubProj.pnr
                                ) ? (
                                  <i
                                    className="bi bi-pin-angle-fill"
                                    onClick={() =>
                                      setPinList((prev) =>
                                        prev.filter(
                                          (item) => item.pnr !== subsubProj.pnr
                                        )
                                      )
                                    }
                                  />
                                ) : (
                                  <i
                                    className="bi bi-pin-angle"
                                    onClick={() =>
                                      setPinList((prev) => [
                                        ...prev,
                                        {
                                          pnr: subsubProj.pnr,
                                          parentId: index,
                                          subId: subIndex,
                                          subsubId: subsubIndex,
                                        },
                                      ])
                                    }
                                  />
                                )}{" "}
                                <br />
                                <ProjectHistory project={subsubProj.pnr}>
                                  <i
                                    className="bi bi-chat-quote"
                                    aria-expanded="false"
                                  />
                                </ProjectHistory>
                                {parseInt(subsubProj.reportOpen) ? (
                                  <>
                                    {parseInt(subsubProj.reportAccepted) ? (
                                      <i
                                        style={{ color: "green" }}
                                        className="bi bi-circle-fill"
                                      />
                                    ) : (
                                      <>
                                        {parseInt(subsubProj.reportSent) ? (
                                          <i
                                            style={{
                                              color: "yellow",
                                            }}
                                            className="bi bi-circle-fill"
                                          />
                                        ) : (
                                          <i
                                            style={{ color: "red" }}
                                            className="bi bi-circle-fill"
                                          />
                                        )}
                                      </>
                                    )}
                                  </>
                                ) : (
                                  /* Rapporten er ikke åpnet */
                                  <>
                                    {parseInt(subsubProj.reportAccepted) ? (
                                      <i
                                        style={{ color: "green" }}
                                        className="bi bi-circle-fill"
                                      />
                                    ) : null}
                                  </>
                                )}
                              </>
                            </td>
                          </tr>
                        );
                      })}
                    </React.Fragment>
                  );
                })}
              </React.Fragment>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}

const convertNumber = (number) => {
  let num = parseInt(number);
  if (isNaN(num)) {
    return number;
  }
  return num.toLocaleString();
};
const formatName = (fullName) => {
  try {
    const nameParts = fullName.split(" "); // Split navnet ved mellomrom
    if (nameParts.length <= 1) return fullName; // Hvis det bare er ett navn, returner det som det er

    const firstName = nameParts[0]; // Første del av navnet
    const initials = nameParts
      .slice(1)
      .map((name) => `${name.charAt(0)}.`)
      .join(" "); // Resten av delene som initialer

    return `${firstName} ${initials}`; // Kombiner fornavn og initialer
  } catch (err) {
    return fullName;
  }
};
